import { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

import ResetPassword from 'components/organisms/reset-password/index';
import Home from '../components/organisms/home';
import useApiCall from '../utils/hooks/useApiCall';
import { fetchAllSettingsApi, fetchCommunityInfoApi } from '../services/settings';
import { GOOGLE_CLIENT_ID } from '../configs/env-vars';
import NotFound from '../components/molecules/not-found';
import PageLoader from '../components/molecules/page-loader';

const HomePage = () => {
  const [settings, settingsLoading, settingsCall] = useApiCall(fetchAllSettingsApi);
  const [communityInfo, communityInfoLoading, communityInfoCall] =
    useApiCall(fetchCommunityInfoApi);

  useEffect(() => {
    settingsCall();
    communityInfoCall({
      url: window.location.href,
    });
  }, []);

  const params = new URLSearchParams(window.location.search);
  const resetCode = params.get('code')?.toLowerCase() || null;

  if (resetCode) {
    return <ResetPassword resetCode={resetCode} />;
  }

  if (window.location.pathname === '/linkedin') {
    return <LinkedInCallback />;
  }

  if (settingsLoading || !settings || communityInfoLoading) {
    return <PageLoader />;
  }

  if (!communityInfo || !communityInfo?.user || !communityInfo.community) {
    return <NotFound />;
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Home settings={settings} communityInfo={communityInfo} />
    </GoogleOAuthProvider>
  );
};

export default HomePage;
