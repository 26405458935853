/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import Header from '../components/molecules/header';
import GetAppQr from '../components/molecules/get-app-qr';
import useCatchUp from '../utils/hooks/useCatchUp';
import PageLoader from '../components/molecules/page-loader';
import InvitedUpcomingCatchUp from '../components/molecules/invited-upcoming-catch-up';
import AvatarSection from '../components/molecules/avatar-section';
import GetTheApp from '../components/molecules/get-the-app';
import CatchUpCard from '../components/molecules/cards/catch-up-card';
import useAuth from '../utils/hooks/useAuth';
import { joinCatchUpApi } from '../services/catch-up';
import NotFound from '../components/molecules/not-found';

const MainWrapper = styled.div`
  @media (min-width: 576px) {
    padding: 0px;
  }

  @media (min-width: 768px) {
    padding: 0px 50px;
  }

  @media (min-width: 992px) {
    padding: 0px 50px;
  }

  @media (min-width: 1200px) {
    padding: 0px 50px;
  }

  @media (min-width: 1400px) {
    padding: 0px 50px;
  }

  .mobile_view {
    display: block;
  }

  .desktop_view {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    .mobile_view {
      display: none;
    }

    .desktop_view {
      display: flex;
      justify-content: space-between;
      background-color: ${colors.faded_grey};
    }
  }
  @media (min-width: 1024px) {
    .left-section {
      margin: 0 2%;
    }

    .right-section {
      margin-right: 10%;
    }

    .left-section {
      flex: 0 0 60%;
      max-width: 60%;
      margin-top: 30px;
    }

    .right-section {
      flex: 0 0 20%;
      max-width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
    }
  }
`;

const CatchUpPage = () => {
  const { stateLoading, meeting, user, expiryDate, token, userSlug, followed, errors } =
    useCatchUp();

  const navigate = useNavigate();
  const { guestUser } = useAuth();

  const joinedObj = {
    meetingId: meeting?.id,
    guestEmail: guestUser?.email,
  };

  const followedCatchUp = async () => {
    try {
      const res = await joinCatchUpApi(joinedObj);
      notification.success({ message: res?.data?.message });
    } catch (error) {
      notification.error({ message: error.message });
    }
  };

  if (stateLoading) {
    return <PageLoader />;
  }

  if (errors?.message !== 'Invalid url') {
    return (
      <MainWrapper>
        <div className='main_wrapper'>
          <Header />
          <InvitedUpcomingCatchUp userName={user?.fullName} />
          <AvatarSection
            avatarUrl={user?.avatar?.url}
            name={user?.fullName}
            expiryDate={expiryDate}
          />
          <div className='mobile_view'>
            <CatchUpCard
              isJoin={followed}
              name={meeting?.place?.name}
              formattedAddress={meeting?.place?.formatted_address}
              startDate={meeting?.startDate}
              startTime={meeting?.startTime}
              onButtonClick={() => {
                if (guestUser) {
                  followedCatchUp();
                } else {
                  navigate(
                    `${`/catch-up-detail`}/${
                      meeting?.id
                    }?token=${token}&userSlug=${userSlug}&avatar=${user?.avatar?.url}`,
                    { state: { meeting, user } }
                  );
                }
              }}
            />
            <GetTheApp name={meeting?.userId?.fullName} />
          </div>

          <div className='desktop_view'>
            <div className='left-section'>
              <CatchUpCard
                isJoin={followed}
                name={meeting?.place?.name}
                formattedAddress={meeting?.place?.formatted_address}
                startDate={meeting?.startDate}
                startTime={meeting?.startTime}
                onButtonClick={() => {
                  if (guestUser) {
                    followedCatchUp();
                  } else {
                    navigate(
                      `${`/catch-up-detail`}/${
                        meeting?.id
                      }?token=${token}&userSlug=${userSlug}&avatar=${user?.avatar?.url}`,
                      { state: { meeting, user } }
                    );
                  }
                }}
              />
            </div>
            <div className='right-section'>
              <GetAppQr name={user?.fullName} />
            </div>
          </div>
        </div>
      </MainWrapper>
    );
  }

  return <NotFound />;
};

export default CatchUpPage;
